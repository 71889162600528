import React from 'react';
import styled from 'styled-components';
import GlobalStyles from './globalStyles';


const StyledWrapper = styled.section`
  width: 90%;
  margin: 95px auto 0;
  z-index: 1;
  position: relative;

  @media(min-width: 1920px) {
    width: 70%;
  }

  @media(min-width: 2000px) {
    width: 60%;
  }

  @media(max-width: 1184px) {
    width: 100%;
  }
`;

const Img = styled.img`
  position: absolute;
  z-index: -1;
  height: 80vh;
  width: auto;

  @media(max-width: 1184px) {
    display: none;
  }
`;

const Layout = props => {
  return (
    <React.Fragment>
      {
        props.images && props.images.map((image, index) => {
          return <Img key={index} src={image.path} className="parallax" style={image.styles} />
        })
      }
      <StyledWrapper>
        <GlobalStyles background={props.background} />
        {props.children}
      </StyledWrapper>
    </React.Fragment>
  )
}

export default Layout;
